import React, { useEffect, useRef, useState } from 'react';

import type {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng/esm';

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from 'agora-rtc-sdk-ng/esm';

import Draggable from 'react-draggable';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import ASSETS from 'assets';
import { agoraAppId, applicantRoles } from 'app.config';
import { toast } from 'react-toastify';
import { renderSeconds } from 'utils';
import { getFinalRoundById, getRTCToken } from 'http/jobService';
import { IFinalInterviewRes } from 'types/api';
import Timer from 'pages/Chat/Components/Timer';
import dayjs from 'dayjs';

const client: IAgoraRTCClient = createClient({
  mode: 'rtc',
  codec: 'vp8',
});
let audioTrack: IMicrophoneAudioTrack;
let videoTrack: ICameraVideoTrack;

const Call = () => {
  const navigate = useNavigate();
  const state: { channel_id: string } = useLocation().state;
  const channel_id = state?.channel_id || '';
  const parent = useRef<HTMLDivElement>(null);
  const draggableRef = useRef<HTMLDivElement>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const { finalRound } = useSelector((state: RootState) => state.utils);

  const myVideo = useRef<HTMLVideoElement>(null);
  const yourVideo = useRef<HTMLVideoElement>(null);

  const channel = useRef(channel_id);
  const appid = useRef(agoraAppId);
  const token = useRef('');

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [muted, setMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [guestJoined, setGuestJoined] = useState(false);
  const [time, setTime] = useState(0);
  const uid = useRef<number | string>('');
  const [timer, setTimer] = useState(0);

  const [finalRoundData, setFinalRoundData] =
    useState<null | IFinalInterviewRes>(null);

  const turnOnCamera = async (flag?: boolean) => {
    flag = flag ?? !isVideoOn;
    setIsVideoOn(flag);

    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    videoTrack = await createCameraVideoTrack();
    if (myVideo.current) videoTrack.play(myVideo.current);
  };

  const turnOnMicrophone = async (flag?: boolean) => {
    flag = flag ?? !isAudioOn;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };

  const leaveChannel = async () => {
    try {
      setIsJoined(false);
      setIsAudioPubed(false);
      setIsVideoPubed(false);

      videoTrack.stop();
      videoTrack.close();
      audioTrack.stop();
      audioTrack.close();

      await client.leave();
    } catch (err: any) {
    } finally {
      navigate('/chat');
    }
  };

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: 'video' | 'audio'
  ) => {
    if (mediaType === 'video') {
      const remoteTrack = await client.subscribe(user, mediaType);

      if (yourVideo.current) {
        if (user.uid != uid.current) {
          remoteTrack.play(yourVideo.current);
        } else {
          remoteTrack.play(myVideo.current!);
        }
      }
      setIsVideoSubed(true);
    }
    if (mediaType === 'audio') {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
    }
  };

  const onUserLeft = (user: IAgoraRTCRemoteUser) => {
    setIsVideoSubed(false);
    setGuestJoined(false);
    leaveChannel();
  };

  const onUserJoined = (user: IAgoraRTCRemoteUser) => {
    if (user.uid != uid.current) {
      setGuestJoined(true);
    }
  };

  const joinChannel = async () => {
    try {
      if (!channel.current) {
        channel.current = 'react-room';
      }

      if (isJoined) {
        await leaveChannel();
      }
      let t = '';
      let _uid = 0;
      if (!token.current) {
        const { data } = await getRTCToken(channel.current);
        token.current = data.data.token;
        t = data.data.token;
        _uid = data.data.uid;
      }

      client.on('user-published', onUserPublish);
      client.on('user-left', onUserLeft);
      client.on('user-joined', onUserJoined);

      const d = await client.join(appid.current!, channel.current, t, _uid);

      uid.current = d;

      const aTrack = await createMicrophoneAudioTrack();
      const vTrack = await createCameraVideoTrack();

      setIsJoined(true);

      await client.publish([aTrack, vTrack]);
      setIsAudioPubed(true);
      setIsVideoPubed(true);
      audioTrack = aTrack;
      videoTrack = vTrack;
      if (myVideo.current) {
        videoTrack.play(myVideo.current);
      }
    } catch (err: any) {
      toast.error(err.message);
      if (videoTrack) {
        videoTrack.close();
        videoTrack.stop();
      }
      if (audioTrack) {
        audioTrack.stop();
        audioTrack.close();
      }
    }
  };

  // const publishVideo = async () => {
  //   await turnOnCamera(true);

  //   if (!isJoined) {
  //     await joinChannel();
  //   }

  //   await client.publish(videoTrack);

  //   setIsVideoPubed(true);
  // };

  // const publishAudio = async () => {
  //   await turnOnMicrophone(true);

  //   if (!isJoined) {
  //     await joinChannel();
  //   }

  //   await client.publish(audioTrack);
  //   setIsAudioPubed(true);
  // };

  const toggleMike = () => {
    if (muted) {
      audioTrack.setEnabled(true);
    } else {
      audioTrack.setEnabled(false);
    }
    setMuted(!muted);
  };

  const toggleVideo = async () => {
    if (videoMuted) {
      videoTrack.setEnabled(true);
    } else {
      videoTrack.setEnabled(false);
    }
    setVideoMuted(!videoMuted);
  };

  const handleGetData = async () => {
    try {
      const { data } = await getFinalRoundById(finalRound);
      setFinalRoundData(data.data);
      const end = dayjs(data.data.end_time);
      const diff = end.diff(dayjs(), 'seconds');
      setTimer(diff);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (parent.current && draggableRef.current) {
      const width = draggableRef.current.offsetWidth;
      const height = draggableRef.current.offsetHeight;
      const parentHeight = parent.current.offsetHeight;
      const parentWidth = parent.current.offsetWidth;
      setPosition({
        x: parentWidth - width - 24,
        y: parentHeight - height - 24,
      });
    }
  }, [guestJoined]);

  useEffect(() => {
    if (client) {
      joinChannel();
    }
    return () => {
      leaveChannel();
    };
  }, [client]);

  useEffect(() => {
    let interval: any = null;
    if (isJoined && guestJoined) {
      interval = setInterval(() => {
        setTime(prev => prev + 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isJoined, guestJoined]);

  useEffect(() => {
    if (finalRound) {
      handleGetData();
    }
  }, []);

  useEffect(() => {
    if (finalRoundData) {
      const end = dayjs(finalRoundData.end_time);
      if (end.isSame(dayjs()) || end.isBefore(dayjs())) {
        leaveChannel();
      }
    }
  }, [time]);

  return (
    <div className='bg-greyBg flex-grow flex flex-col 2xl:py-10 py-4 2xl:px-12 px-4 2xl:gap-10 gap-5'>
      <div className='flex-grow flex items-center justify-center relative'>
        <div className='absolute top-0 left-0 w-full h-full z-20'>
          <div
            className={classNames(
              'w-full h-full relative',
              !guestJoined && 'hidden'
            )}
            ref={parent}
          >
            <Draggable
              bounds='parent'
              position={position}
              onDrag={(d, data) => {
                setPosition({ x: data.x, y: data.y });
              }}
            >
              <div
                className={classNames(
                  '2xl:w-[456px] w-[380px] aspect-video bg-[#4D4D4D]  rounded-[16px] cursor-move shadow-[0px_4px_4px_0px_#00000040] flex items-center justify-center relative'
                )}
                ref={draggableRef}
              >
                <div className='w-[100px] aspect-square bg-secondary rounded-md grid place-items-center'>
                  <p className='text-[36px] font-semibold text-white uppercase'>
                    {applicantRoles.includes(user?.role || '') ? (
                      <>
                        {finalRoundData?.company_name &&
                          finalRoundData?.company_name[0]}
                      </>
                    ) : (
                      <>
                        {finalRoundData?.first_name &&
                          finalRoundData?.last_name &&
                          finalRoundData.first_name[0] +
                            finalRoundData.last_name[0]}
                      </>
                    )}
                  </p>
                </div>

                <video
                  ref={yourVideo}
                  className={classNames(
                    'w-full h-full !object-contain absolute left-0 top-0',
                    !isVideoSubed && 'hidden'
                  )}
                ></video>
                <div className='absolute bottom-0 left-0 w-full 2xl:px-6 2xl:pb-6 px-4 pb-3'>
                  <p className='2xl:text-2xl text-lg font-semibold text-white capitalize'>
                    {applicantRoles.includes(user?.role || '') ? (
                      <>{finalRoundData?.company_name}</>
                    ) : (
                      <>
                        {finalRoundData?.first_name} {finalRoundData?.last_name}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </Draggable>
          </div>
        </div>

        <div
          className={classNames(
            '2xl:w-[256px] w-[200px] aspect-square bg-primary rounded-[16px] grid place-items-center',
            !videoMuted && 'hidden'
          )}
        >
          <p className='text-[92px] font-semibold text-white uppercase'>
            {user && user.first_name[0] + user.last_name[0]}
          </p>
        </div>
        <div
          className={classNames(
            'w-full h-full bg-black  relative',
            videoMuted && 'hidden'
          )}
        >
          {/* <img
              src={ASSETS.v1}
              
              alt=''
            /> */}

          <video
            ref={myVideo}
            className='w-full h-full !object-contain absolute top-0 left-0'
          ></video>
        </div>
      </div>
      <div className='flex items-center justify-center relative '>
        <div className='absolute bottom-0 left-0'>
          <p className='2xl:text-2xl text-xl text-white font-semibold capitalize'>
            {user?.first_name} {user?.last_name}
          </p>
          <p className='2xl:text-xl text-lg text-white font-semibold 2xl:mt-[15px] mt-3'>
            {time > 0 ? renderSeconds(time) : 'Waiting...'}
          </p>
        </div>
        {finalRoundData && (
          <div className='absolute bottom-0 right-0'>
            <span className='text-sm text-white'>
              Will end at {dayjs(finalRoundData?.end_time).format('hh:mm A')}
            </span>
          </div>
        )}
        <div className='flex items-center 2xl:gap-12 gap-5 2xl:pb-6 pb-3'>
          <button
            className={classNames(
              '2xl:w-20 2xl:h-14 w-16 h-12 bg-[#FFFFFF4D] rounded-[50px] grid place-items-center',
              muted && 'bg-[#FFFFFFE5]'
            )}
            onClick={toggleMike}
          >
            <img
              src={muted ? ASSETS.mike_muted : ASSETS.mike}
              className='2xl:w-auto w-6'
              alt=''
            />
          </button>
          <button
            className='2xl:w-[72px] w-[60px] aspect-square rounded-full bg-[#F14A49] grid place-items-center'
            onClick={leaveChannel}
          >
            <img src={ASSETS.call_reject} className='2xl:w-auto w-8' alt='' />
          </button>
          <button
            className={classNames(
              '2xl:w-20 2xl:h-14 w-16 h-12 bg-[#FFFFFF4D] rounded-[50px] grid place-items-center',
              videoMuted && 'bg-[#FFFFFFE5]'
            )}
            onClick={toggleVideo}
          >
            <img
              src={videoMuted ? ASSETS.vide_muted : ASSETS.video_cam}
              alt=''
              className='2xl:w-auto w-6'
            />
          </button>
        </div>
        <div />
      </div>
    </div>
  );
};

export default Call;
