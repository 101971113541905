import React, { FC, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { IBlockedUser } from 'types/api';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';
import { blockUser } from 'http/userService';
import { toast } from 'react-toastify';

interface IProps {
  user: IBlockedUser;
}

const BlockedUser: FC<IProps> = ({ user }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);

  const handleUnblock = async () => {
    try {
      setLoading(true);
      await blockUser(user.user_id);
      toast.success('User unblocked successfully');
      dispatch.reports.handleGetBlockedUsers();
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-5'>
        <img
          src={user.image_url || ASSETS.preview}
          className='2xl:size-[50px] size-[45px] object-cover rounded-full'
          alt=''
        />
        <p className='2xl:text-xl text-lg font-medium'>
          {user.first_name} {user.last_name}
        </p>
      </div>
      <Button
        variant='outlined'
        label='UNBLOCK'
        className='2xl:!h-10 !h-9 !text-sm'
        onClick={handleUnblock}
        loading={loading}
      />
    </div>
  );
};

const BlockedUsers = () => {
  const dispatch = useDispatch<Dispatch>();

  const { loading, blockedUsers } = useSelector(
    (state: RootState) => state.reports
  );

  useEffect(() => {
    dispatch.reports.handleGetBlockedUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='flex-grow 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col gap-4'>
      {blockedUsers.length === 0 && loading && (
        <div className='py-10 flex items-center justify-center'>
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      )}
      {blockedUsers?.map(user => (
        <BlockedUser key={user.id} user={user} />
      ))}
    </div>
  );
};

export default BlockedUsers;
