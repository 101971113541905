import React, { FC, useState } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import { jobUnpostReasons } from 'app.config';
import Button from 'components/Button/Button';
import classNames from 'classnames';
import { unpostJob } from 'http/jobService';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import ASSETS from 'assets';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  onUnpost: () => void;
}

const UnpostJobReason: FC<IProps> = ({ open, setOpen, id, onUnpost }) => {
  const dispatch = useDispatch<Dispatch>();
  const [selected, setSelected] = useState('');

  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    setOpen(false);
  };

  const handleUnpost = async () => {
    try {
      setLoading(true);
      await unpostJob(id);
      dispatch.job.handleGetMyJobs();
      handleClose();
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogLayout open>
      <div className='w-full max-w-[480px] bg-grey-100 rounded-[12px] p-[22px]'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            onClick={handleClose}
            className='cursor-pointer'
            alt=''
          />
        </div>
        <p className='2xl:text-2xl text-xl text-primary text-center font-medium'>
          Reason for Closing
        </p>
        <p className='text-base text-placeholder text-center mt-[10px]'>
          Please select the reason that applies
        </p>

        <div className='flex flex-col gap-1 mt-4'>
          {jobUnpostReasons.map(i => (
            <div
              key={i}
              className='bg-white border-[0.5px] border-grey-400 h-11 rounded px-[19px]
                  flex gap-2 items-center cursor-pointer shadow-md'
              onClick={() => setSelected(i)}
            >
              <img
                src={selected === i ? ASSETS.circleFilled : ASSETS.circleGrey}
                alt=''
              />
              <p
                className={classNames(
                  'text-base font-medium',
                  selected === i ? '' : 'opacity-50'
                )}
              >
                {i}
              </p>
            </div>
          ))}
        </div>
        <Button
          label='Close'
          className='!text-lg w-full mt-[14px]'
          onClick={handleUnpost}
          loading={loading}
          disabled={!selected}
        />
      </div>
    </DialogLayout>
  );
};

export default UnpostJobReason;
