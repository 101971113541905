import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import styled from 'styled-components';
import SavedJobs from './components/SavedJobs';
import AppliedJobs from './components/AppliedJobs';
import JobsViewed from './components/JobsViewed';
import SavedProfiles from './components/SavedProfiles';
import Interviews from './components/Interviews';
import ReceivedJobApp from './components/ReceivedJobApp';
import EmployerInterViews from './components/EmployerInterViews';
import SelectedApplication from './components/SelectedApplication';
import { t } from 'i18next';
import { applicantRoles, employerRoles } from 'app.config';
import SavedCompanies from './components/SavedCompanies';
import { IApplicationData } from 'types/interfaces';
import { getJobApplicationById } from 'http/jobService';

type TAB =
  | 'saved'
  | 'applied'
  | 'interview'
  | 'job-viewed'
  | 'saved-profile'
  | 'received-job-applications';

const Myjobs: FC = () => {
  const [tab, setTab] = useState<TAB>('saved');

  const dispatch = useDispatch<Dispatch>();

  const { user, role } = useSelector((state: RootState) => state.auth);
  const {
    savedJobs,
    appliedJobs,
    viewedJobs,
    savedProfiles,
    applications,
    interviews,
  } = useSelector((state: RootState) => state.myjobs);
  const [selectedApplication, setSelectedApplication] = useState('');
  const [isApplicantRole, setIsApplicantRole] = useState(false);

  const [, setApplication] = useState<IApplicationData | null>(null);

  const [, setLoading] = useState(false);

  const handleTab = (tab: TAB) => {
    setTab(tab);
    setSelectedApplication('');
  };

  const handleFetchApplication = async () => {
    try {
      setLoading(true);
      const { data } = await getJobApplicationById(selectedApplication);
      setApplication(data.data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employerRoles.includes(user?.role || '')) {
      setIsApplicantRole(false);
      setTab('received-job-applications');
    } else {
      setIsApplicantRole(true);
    }
    dispatch.myjobs.handleGetSavedJobs();
    dispatch.myjobs.handleGetAppliedJobs();
    dispatch.myjobs.handleGetViewedJobs();
    dispatch.myjobs.handleGetSavedProfiles();
    if (user) {
      if (user.role) {
        dispatch.myjobs.handleGetInterview(user.role);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (selectedApplication) {
      setApplication(null);
      handleFetchApplication();
    }
    // eslint-disable-next-line
  }, [selectedApplication]);

  return (
    <Container>
      <div className='w-full max-w-[821px] mx-auto'>
        <div className='tabs flex items-center gap-2 '>
          {isApplicantRole && (
            <>
              <Tab
                onClick={() => handleTab('saved')}
                className={classNames(
                  '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                  tab === 'saved'
                    ? 'bg-primary text-white'
                    : 'bg-white border border-grey-600'
                )}
              >
                <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                  {t('Saved Jobs')} ({savedJobs.length})
                </p>
              </Tab>
              <Tab
                onClick={() => handleTab('applied')}
                className={classNames(
                  '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                  tab === 'applied'
                    ? 'bg-primary text-white'
                    : 'bg-white border border-grey-600'
                )}
              >
                <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                  {t('Applied Jobs')} ({appliedJobs.length})
                </p>
              </Tab>
            </>
          )}
          {!isApplicantRole && (
            <Tab
              onClick={() => handleTab('received-job-applications')}
              className={classNames(
                '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                tab === 'received-job-applications'
                  ? 'bg-primary text-white'
                  : 'bg-white border border-grey-600'
              )}
            >
              <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                {t('Received Job Applications')} ({applications})
              </p>
            </Tab>
          )}
          <Tab
            onClick={() => handleTab('interview')}
            className={classNames(
              '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
              tab === 'interview'
                ? 'bg-primary text-white'
                : 'bg-white border border-grey-600'
            )}
          >
            <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
              {t('Final Round Interview')} ({interviews})
            </p>
          </Tab>
          {isApplicantRole && (
            <Tab
              onClick={() => handleTab('job-viewed')}
              className={classNames(
                '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                tab === 'job-viewed'
                  ? 'bg-primary text-white'
                  : 'bg-white border border-grey-600'
              )}
            >
              <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                {t('Jobs Viewed')} ({viewedJobs.length})
              </p>
            </Tab>
          )}
          <Tab
            onClick={() => handleTab('saved-profile')}
            className={classNames(
              '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
              tab === 'saved-profile'
                ? 'bg-primary text-white'
                : 'bg-white border border-grey-600'
            )}
          >
            <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
              {t('Saved Profiles')} ({savedProfiles.length})
            </p>
          </Tab>
        </div>
      </div>
      <div
        className={classNames(
          'flex-grow h-0 overflow-auto',
          selectedApplication && '!max-w-[100vw] pt-5'
        )}
      >
        <div
          className={classNames(
            'w-full h-full max-w-[821px] mx-auto',
            selectedApplication && '!max-w-[100vw]'
          )}
        >
          {selectedApplication ? (
            <SelectedApplication
              selectedApplicationId={selectedApplication}
              // setApplicationId={setSelectedApplication}
            />
          ) : (
            <>
              {tab === 'received-job-applications' && (
                <ReceivedJobApp setSelected={setSelectedApplication} />
              )}
              {tab === 'saved' && <SavedJobs />}
              {tab === 'applied' && <AppliedJobs />}
              {tab === 'interview' && (
                <>
                  <Interviews />
                  {/* {employerRoles.includes(user?.role || '') ? (
                    <EmployerInterViews />
                  ) : (
                    <Interviews />
                  )} */}
                </>
              )}
              {tab === 'job-viewed' && <JobsViewed />}
              {tab === 'saved-profile' && (
                <>
                  {applicantRoles.includes(user?.role || '') ? (
                    <SavedCompanies />
                  ) : (
                    <SavedProfiles />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Myjobs;

const Container = styled.div`
  width: 100%;
  margin-inline: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Tab = styled.div`
  cursor: pointer;
  transition: 0.4s ease;
`;
