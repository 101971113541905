import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IAppliedJob,
  IFinalRoundData,
  IReceivedJobsData,
  ISavedJob,
  IViewedJobs,
} from 'types/interfaces';
import {
  appliedJobs,
  candidateInterview,
  employerInterview,
  getSavedJobs,
  receivedJobApplications,
  viewesJobs,
} from 'http/jobService';
import { getSavedProfiles } from 'http/userService';
import { ROLES } from 'types/types';
import { applicantRoles } from 'app.config';
import { IFinalInterviewRes } from 'types/api';

interface IState {
  loading: boolean;
  savedJobs: ISavedJob[];
  appliedJobs: IAppliedJob[];
  viewedJobs: IViewedJobs[];
  savedProfiles: any[];
  receivedJobs: null | IReceivedJobsData;
  applications: number;
  selectedJobTitle: string;
  finalInterviews: null | IFinalRoundData;
  interviews: number;
}

export const myjobs = createModel<RootModel>()({
  name: 'myjobs',
  state: {
    loading: false,
    savedJobs: [],
    appliedJobs: [],
    viewedJobs: [],
    savedProfiles: [],
    receivedJobs: null,
    applications: 0,
    selectedJobTitle: '',
    finalInterviews: null,
    interviews: 0,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSavedJobs(state, payload: ISavedJob[]) {
      state.savedJobs = payload;
    },
    setAppliedJobs(state, payload: IAppliedJob[]) {
      state.appliedJobs = payload;
    },
    setViewedJobs(state, payload: IViewedJobs[]) {
      state.viewedJobs = payload;
    },
    setSavedProfiles(state, payload: any[]) {
      state.savedProfiles = payload;
    },
    setReceivedJobs(state, payload: IReceivedJobsData | null) {
      state.receivedJobs = payload;
    },
    setApplications(state, payload: number) {
      state.applications = payload;
    },
    setSelectedJobTitle(state, payload: string) {
      state.selectedJobTitle = payload;
    },
    setFinalInterview(state, payload: IFinalRoundData) {
      state.finalInterviews = payload;
    },
    setInterviews(state, payload: number) {
      state.interviews = payload;
    },
  },
  effects: dispatch => ({
    async handleGetSavedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await getSavedJobs();
        const list = data.data.filter((item: any) => item.job_status !== 2);
        dispatch.myjobs.setSavedJobs(list);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetAppliedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await appliedJobs();
        const list = data.data.filter((item: any) => item.job_status !== 2);
        dispatch.myjobs.setAppliedJobs(list);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetViewedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await viewesJobs();
        const list = data.data.filter(item => item.job_status !== 2);
        dispatch.myjobs.setViewedJobs(list);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetSavedProfiles() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await getSavedProfiles();
        dispatch.myjobs.setSavedProfiles(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetReceivedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await receivedJobApplications();
        const list = data.data.filter((item: any) => item.job_status !== 2);
        dispatch.myjobs.setApplications(list.length);
        const groupedData: IReceivedJobsData = list.reduce(
          (acc: IReceivedJobsData, item) => {
            const key = item.m_job_title;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {}
        );
        dispatch.myjobs.setReceivedJobs(groupedData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetInterview(payload: ROLES) {
      try {
        console.log(payload);
        dispatch.myjobs.setLoading(true);
        let list: IFinalInterviewRes[] = [];
        if (applicantRoles.includes(payload)) {
          const { data } = await candidateInterview();
          console.log(data.data);
          list = data.data;
        } else {
          const { data } = await employerInterview();
          list = data.data;
        }
        dispatch.myjobs.setInterviews(list.length);
        const groupedData: IFinalRoundData = list.reduce(
          (acc: IFinalInterviewRes, item) => {
            const key = item.job_title;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          // @ts-ignore
          {}
        );
        dispatch.myjobs.setFinalInterview(groupedData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
  }),
});
