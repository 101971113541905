import React, { FC, useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { AgoraContext } from 'context/AgoraContext';
import { IFinalInterviewRes } from 'types/api';
import AC from 'agora-chat';

import ASSETS from 'assets';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { InterviewStatus } from 'types/types';
import { applicantRoles } from 'app.config';
import { getFinalRoundById, updateInterviewStatus } from 'http/jobService';
import classNames from 'classnames';
import Timer from './Timer';

dayjs.extend(utc);
interface IProps {
  msg: any;
}

const ActionMessage: FC<IProps> = ({ msg }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const conn = useContext(AgoraContext);
  const { user } = useSelector((state: RootState) => state.auth);
  const { selected } = useSelector((state: RootState) => state.chat);
  const [timer, setTimer] = useState(0);

  const [data, setData] = useState<null | IFinalInterviewRes>(
    JSON.parse(msg?.msg) || null
  );
  const handleNavigate = () => {
    if (data) {
      const channel_id = data.channel_id;
      dispatch.utils.setFinalRound(data.id);
      navigate(`/call`, { state: { channel_id } });
    }
  };

  const handleAcceptOrReject = async (val: InterviewStatus) => {
    try {
      if (data && selected) {
        const msgData = { ...data };
        // @ts-ignore
        msgData.status = val === 'accepted' ? 'scheduled' : val;
        setData({ ...msgData });
        await updateInterviewStatus(
          data?.id,
          val === 'accepted' ? 'scheduled' : val
        );
        const m = AC.message.create({
          type: 'txt',
          chatType: 'groupChat',
          msg: JSON.stringify(msgData),
          to: selected.group_id,
        });
        await conn.modifyMessage({
          messageId: msg.id,
          // @ts-ignore
          modifiedMessage: m,
        });

        if (val === 'accepted') {
          const currentTime = dayjs();
          const start = dayjs(msgData.time);
          if (currentTime.isBefore(start)) {
            const diff = start.diff(currentTime, 'seconds');
            setTimer(diff);
          } else {
            setTimer(0);
          }
        }
      }
    } catch (err: any) {
    } finally {
    }
  };

  const handleUpdateStauts = async () => {
    const s = msg?.msg;
    let d: IFinalInterviewRes = JSON.parse(s);
    const id = d.id;

    setData(d);
    const { data } = await getFinalRoundById(id);
    d = data.data;
    setTimeout(() => {
      const start_time = d.time;
      const end_time = d.end_time;
      const status = d.status;
      // const date = d.date;

      const currentTime = dayjs();
      const start = dayjs(start_time);
      const end = dayjs(end_time);
      if (
        currentTime.isBefore(start) &&
        (status === 'accepted' || status === 'scheduled')
      ) {
        if (start.isAfter(currentTime)) {
          const diff = start.diff(currentTime, 'seconds');
          setTimer(diff);
        } else {
          setTimer(0);
        }
      }
      if (currentTime.isAfter(start) && currentTime.isBefore(end)) {
        if (status === 'started' || status === 'cancelled') {
          return;
        } else {
          if (status === 'pending') {
            handleAcceptOrReject('cancelled');
          } else {
            handleAcceptOrReject('started');
          }
        }
      } else if (currentTime.isAfter(end)) {
        if (status === 'completed' || status === 'cancelled') {
          return;
        } else {
          if (status === 'pending') {
            handleAcceptOrReject('cancelled');
          } else {
            handleAcceptOrReject('completed');
          }
        }
      }
    }, 1000);
  };

  useEffect(() => {
    handleUpdateStauts();
    // eslint-disable-next-line
  }, [msg]);

  return (
    <div className='w-full 2xl:max-w-[320px] max-w-[300px] bg-grey-100 rounded-[12px] shadow-popup'>
      <div className='px-[17px] pt-[11px] pb-[5px]'>
        <p className='2xl:text-xl text-lg text-primary font-medium'>
          {selected?.m_company_name || selected?.name}
        </p>
        <p className='2xl:text-sm text-xs font-medium'>
          {selected?.job_title || selected?.title}
        </p>
        <p className='2xl:text-sm text-xs font-medium mt-[3px]'>
          Final Round{' '}
          <span
            className={classNames(
              'capitalize',
              'text-black',
              data?.status === 'pending' && 'text-warning',
              data?.status === 'cancelled' && 'text-red',
              data?.status === 'accepted' && 'text-success',
              // @ts-ignore
              data?.status === 'scheduled' && 'text-success'
            )}
          >
            ({data?.status === 'accepted' ? 'Scheduled' : data?.status})
          </span>
        </p>
        <div className='flex items-center gap-6 mt-2'>
          <div className='flex items-center gap-2'>
            <img src={ASSETS.calender} alt='' />
            <p className='text-[13px] font-medium'>
              {data?.date && dayjs.utc(data.date).format('DD/MM/YY')}
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={ASSETS.clock} alt='' />
            <p className='text-[13px] font-medium'>
              {data?.time && dayjs(data.time).format('hh:mm A')} -{' '}
              {data?.end_time && dayjs(data.end_time).format('hh:mm A')}
            </p>
          </div>
        </div>
      </div>

      {data?.status === 'scheduled' && timer > 0 && (
        <Timer
          timeInSeconds={timer}
          setTime={setTimer}
          onEnd={handleUpdateStauts}
        />
      )}

      {applicantRoles.includes(user?.role || '') &&
      data?.status !== 'started' ? (
        <>
          {data?.status === 'pending' && (
            <div className='border-t border-t-grey-400 flex'>
              <button
                className='h-[42px] flex-grow border-r border-r-grey-400 font-medium text-sm text-[#FE858C]'
                onClick={() => handleAcceptOrReject('cancelled')}
              >
                Decline
              </button>
              <button
                className='h-[42px] flex-grow text-[#039855] font-medium text-sm'
                onClick={() => handleAcceptOrReject('accepted')}
              >
                Accept
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {data?.status === 'started' && (
            <div className='p-2'>
              <button
                className='w-full py-2 flex justify-center bg-gray-200 rounded-lg cursor-pointer'
                onClick={handleNavigate}
              >
                <img src={ASSETS.videoIcon} alt='' />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActionMessage;
