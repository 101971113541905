import React, { useCallback, useContext, useEffect, useState } from 'react';
import ASSETS from 'assets';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useTranslation } from 'react-i18next';

import Search from './components/Search/Search';
import LocationSearch from './components/LocationSearch/LocationSearch';
import classNames from 'classnames';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import Filters from './components/Filters/Filters';
import Profile from './components/Profile/Profile';
import Notification from './components/Notification/Notification';
import FilterDialogs from 'Dialogs/FilterDialogs/FilterDialogs';
import ChoosePath from 'Dialogs/ChoosePath/ChoosePath';
import ActionDialog from 'Dialogs/ActionDialog/ActionDialog';
import debounce from 'utils';
import { AgoraContext } from 'context/AgoraContext';

const CONFIG = [
  {
    label: 'Explore',
    icon: ASSETS.home,
    activeIcon: ASSETS.homeActive,
    path: '/explore',
  },
  {
    label: 'Following',
    icon: ASSETS.users,
    activeIcon: ASSETS.usersActive,
    path: '/following',
  },
  {
    label: 'My Jobs',
    icon: ASSETS.bag,
    activeIcon: ASSETS.bagActive,
    path: '/my-jobs',
  },
  {
    label: 'Chat',
    icon: ASSETS.chat,
    activeIcon: ASSETS.chatActive,
    path: '/chat',
  },
];

const DashboardLayout = () => {
  const dispatch = useDispatch<Dispatch>();
  const [config, setConfig] = useState<typeof CONFIG>([]);
  const [search, setSearch] = useState('');
  const { actionDialog } = useSelector((state: RootState) => state.utils);
  const [location, setLocation] = useState('');

  const pathname = useLocation().pathname;

  const conn = useContext(AgoraContext);

  const [t] = useTranslation();

  const { user } = useSelector((state: RootState) => state.auth);
  const { roleModal } = useSelector((state: RootState) => state.utils);
  const { count } = useSelector((state: RootState) => state.chat);

  const searchUser = (search: string, location: string) => {
    dispatch.search.handleSearch({ search, location });
  };

  // eslint-disable-next-line
  const debounceSearchUsers = useCallback(
    debounce(
      (searchTerm: string, location: string) =>
        searchUser(searchTerm, location),
      1000
    ),
    []
  );

  const handleSearchUsers = (search: string, location: string) => {
    debounceSearchUsers(search, location);
  };

  const handleCount = () => {
    const pathname = window.location.pathname;
    if (pathname === '/chat') {
      dispatch.chat.setCount(0);
    } else {
      dispatch.chat.incrementCount();
    }
  };

  useEffect(() => {
    if (user?.role === 'A' || user?.role === 'RA' || user?.role === 'SA') {
      setConfig(CONFIG);
    } else {
      setConfig(CONFIG.filter(item => item.label !== 'Following'));
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    handleSearchUsers(search, location);
    // eslint-disable-next-line
  }, [search, location]);

  useEffect(() => {
    dispatch.notifications.handleGetNotifications();
    const interval = setInterval(() => {
      dispatch.notifications.handleGetNotifications();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    conn.addEventHandler('connection&message', {
      onConnected: () => {
        if (user) {
          dispatch.chat.handleFetchChats({ conn, user });
        }
      },
      onDisconnected: () => {
        console.log('Disconnected');
      },
      onError: error => {
        console.log('on error', error);
      },
      onMessage: message => {
        if (user) {
          console.log('Fetching chats');
          dispatch.chat.handleFetchChats({ conn, user });
        }
        handleCount();
      },
    });
    // eslint-disable-next-line
  }, [conn, user]);

  return (
    <Container>
      <header className='2xl:h-[72px] h-[64px] border-b sticky bg-white border-b-grey-600 flex items-center px-6 justify-between z-10'>
        <div className='flex items-center gap-3 flex-grow'>
          <div className='flex items-center gap-2'>
            <div className='2xl:size-9 size-8 rounded-md  bg-primary grid place-items-center'>
              <img src={ASSETS.logo} alt='' />
            </div>
            <p className='2xl:text-22 text-xl font-medium'>LOGO</p>
          </div>
          <Search value={search} setValue={setSearch} />
          <LocationSearch location={location} setLocation={setLocation} />
        </div>

        <div className='flex items-center gap-3'>
          <img
            src={ASSETS.addBtn}
            className='cursor-pointer 2xl:w-[40px] w-8'
            onClick={() => dispatch.utils.setRoleModal(true)}
            alt=''
          />
          <Notification />
          <Profile />
        </div>
      </header>
      <div
        className={classNames(
          'flex-grow h-0 overflow-auto bg-grey-100 flex  px-4 py-4 2xl:gap-[29px] gap-4',
          pathname === '/call' && '!p-0'
        )}
      >
        {pathname !== '/call' && (
          <FiltersAndNav>
            <div className='bg-white px-3 py-3 border flex flex-col gap-[6px] border-grey-600 rounded-md'>
              {config.map(item => (
                <NavLink
                  key={item.label}
                  className='anchor'
                  to={item.path}
                  children={navData => (
                    <div
                      className={classNames(
                        'element flex items-center transition duration-300 h-10 px-6 gap-2 cursor-pointer relative',
                        navData.isActive && 'bg-primary rounded-md'
                      )}
                    >
                      <img
                        src={navData.isActive ? item.activeIcon : item.icon}
                        alt=''
                      />
                      <p
                        className={classNames(
                          'text-base font-medium',
                          navData.isActive && 'text-white'
                        )}
                      >
                        {t(item.label)}{' '}
                      </p>
                      {item.label === 'Chat' && count > 0 && (
                        <div className='size-2 bg-red rounded-full absolute left-5 top-2'></div>
                      )}
                    </div>
                  )}
                />
              ))}
            </div>
            <Filters />
          </FiltersAndNav>
        )}
        <main className='flex-grow flex flex-col w-[calc(100%_-_285px)]'>
          <Outlet />
        </main>
      </div>
      <FilterDialogs />
      {roleModal && <ChoosePath open={roleModal} />}
      {actionDialog && actionDialog.open && <ActionDialog />}
    </Container>
  );
};

export default DashboardLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FiltersAndNav = styled.div`
  width: 245px;
  min-width: 245px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
