import React, { FC, useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ASSETS from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';
import dayjs from 'dayjs';

interface IProps {
  error?: string;
  label?: string;
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  noLabel?: boolean;
  containerClassName?: string;
  disablePast?: boolean;
}

const DatePicker: FC<IProps> = ({
  error,
  label = 'Enter Date*',
  placeholder = 'Select Date',
  value,
  onChange,
  noLabel = false,
  containerClassName,
  disablePast = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleCalender = () => {
    setOpen(!open);
  };

  const closeCalender = () => {
    setOpen(false);
  };

  return (
    <div className='w-full'>
      {!noLabel && <p className='text-base font-medium'>{label}</p>}
      <div className='wrapper w-full relative'>
        <div
          className={classNames(
            'flex items-center justify-between h-[56px] border border-grey-400 rounded-lg px-[16px] cursor-pointer',
            error && 'border-red',
            containerClassName
          )}
          onClick={toggleCalender}
        >
          <p className='text-base text-placeholder'>
            {value ? dayjs(value).format('MMM, DD, YYYY') : placeholder}
          </p>
          <img src={ASSETS.calenderD} className='2xl:w-auto w-5' alt='' />
        </div>

        {open && (
          <ClickAwayListener onClickAway={closeCalender}>
            <div className='bg-white border border-grey-400 w-[330px] absolute z-20 bottom-0 translate-y-[calc(100%_+_10px)] rounded-md'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={
                    value
                      ? dayjs(value).isValid()
                        ? dayjs(value)
                        : null
                      : null
                  }
                  onChange={(val: dayjs.Dayjs) => {
                    onChange(val.toString());
                  }}
                  disablePast={disablePast}
                />
              </LocalizationProvider>
            </div>
          </ClickAwayListener>
        )}
      </div>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
