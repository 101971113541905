import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Button from 'components/Button/Button';
import NotFound from './NotFound';

import ASSETS from 'assets';
import { IFinalInterviewRes } from 'types/api';
import { FC, useEffect, useRef, useState } from 'react';
import { ICompany } from 'types/interfaces';
import { getCompanyById } from 'http/companyService';

import dayjs from 'dayjs';
import classNames from 'classnames';

interface AccordionProps {
  interviews: IFinalInterviewRes[];
  setSelected: React.Dispatch<React.SetStateAction<null | IFinalInterviewRes>>;
  // onClick: (val: string) => void;
}

const Accordion: FC<AccordionProps> = ({ interviews, setSelected }) => {
  const panel = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [company, setCompany] = useState<null | ICompany>(null);

  const handleClick = (d: IFinalInterviewRes) => {
    if (d.status === 'accepted') {
      setSelected(d);
    }
  };

  const handleAccordion = () => {
    if (panel.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        imgRef.current!.style.transform = 'rotate(90deg)';
        panel.current.style.overflowY = 'hidden';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        panel.current.classList.add('open');
        imgRef.current!.style.transform = 'rotate(-90deg)';
      }
    }
  };

  // eslint-disable-next-line
  const handleCloseAccordion = () => {
    if (panel.current) {
      panel.current.style.maxHeight = '0px';
      panel.current.classList.remove('open');
      imgRef.current!.style.transform = 'rotate(90deg)';
      panel.current.style.overflowY = 'hidden';
    }
  };

  const handleGetCompany = async () => {
    try {
      if (interviews[0].company_id) {
        const { data } = await getCompanyById(interviews[0].company_id);
        setCompany(data.data);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetCompany();
    // eslint-disable-next-line
  }, []);

  return (
    // <ClickAwayListener onClickAway={handleCloseAccordion}>
    <div className='w-full'>
      <div
        className='flex items-center 2xl:gap-[29px] gap-4 rounded-lg shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer relative'
        onClick={handleAccordion}
      >
        <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
          <img src={company?.company_logo || ASSETS.preview} alt='' />
        </div>
        <div>
          <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
            {interviews[0].company_name}
          </p>
          <p className='2xl:text-lg text-base font-medium leading-6'>
            {interviews[0].job_title}
          </p>
          <p className='2xl:text-base text-sm'>
            Interviews: ({interviews.length}){/* ({jobs.length}) */}
          </p>
        </div>
        <img
          src={ASSETS.chevronRight}
          className='w-6 ml-auto overflow-hidden duration-300 transition-all rotate-90'
          ref={imgRef}
          alt=''
        />
      </div>
      <div
        className='page max-h-0 overflow-hidden transition-all duration-300'
        ref={panel}
      >
        <div className='pt-8 flex flex-col gap-2 '>
          {interviews.map(i => (
            <Job
              key={i.id}
              className={classNames(
                'rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4 ',
                i.status === 'accepted' && 'cursor-pointer'
              )}
              onClick={() => handleClick(i)}
            >
              <p className='2xl:text-22 text-lg text-primary font-medium'>
                {i.company_name}
              </p>
              <p className='2xl:text-base text-sm font-medium my-1'>
                {i.job_title}
              </p>
              <p className='2xl:text-base text-sm font-medium'>
                Final Round{' '}
                <span
                  className={classNames(
                    'text-black',
                    i.status === 'pending' && 'text-warning',
                    i.status === 'accepted' && 'text-success',
                    i?.status === 'cancelled' && 'text-red',
                    // @ts-ignore
                    i?.status === 'scheduled' && 'text-success'
                  )}
                >
                  <i className='text-inherit capitalize'>
                    {i.status === 'accepted' ? 'Scheduled' : i.status}
                  </i>
                </span>
              </p>
              <div className='flex items-center gap-5 mt-2'>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.calender} alt='' />
                  <p className='text-13 font-medium'>
                    {dayjs(i.date).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.clock} alt='' />
                  <p className='text-13 font-medium'>
                    {dayjs(i.time).format('hh:mm A')} -{' '}
                    {dayjs(i.end_time).format('hh:mm A')}
                  </p>
                </div>
              </div>
            </Job>
          ))}
        </div>
      </div>
    </div>
    // </ClickAwayListener>
  );
};

const Interviews = () => {
  const [selected, setSelected] = useState<null | IFinalInterviewRes>(null);
  const { finalInterviews, interviews } = useSelector(
    (state: RootState) => state.myjobs
  );
  return (
    <Container className='2xl:pt-[33px] pt-5'>
      {interviews === 0 ? (
        <NotFound message='A Final Round interview has not been set up yet. Keep applying!' />
      ) : (
        <JobsSection>
          {selected ? (
            <div className='bg-white shadow-mm rounded-lg w-full max-w-[587px] mx-auto pt-10 py-14 flex flex-col items-center relative'>
              <img
                src={ASSETS.cross}
                className='absolute right-2 top-2 cursor-pointer'
                onClick={() => setSelected(null)}
                alt=''
              />
              <img src={ASSETS.handshake} alt='' />
              <p className='text-32 text-primary font-medium'>
                You’re all set!
              </p>
              <p className='text-base mt-6'>
                Employer Name:{' '}
                <span className='font-medium'>{selected.company_name}</span>
              </p>
              <p className='text-base mt-3 mb-4'>
                Candidate Name:{' '}
                <span className='font-medium'>
                  {selected.first_name} {selected.last_name}
                </span>
              </p>
              <p className='text-base'>
                Job Title:{' '}
                <span className='font-medium'>{selected.job_title}</span>
              </p>
              <div className='flex items-center gap-5 mt-[29px]'>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.calender} alt='' />
                  <p className='text-13 font-medium'>
                    {' '}
                    {selected && dayjs(selected.date).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.clock} alt='' />
                  <p className='text-13 font-medium'>
                    {selected && dayjs(selected.time).format('hh:mm A')} -{' '}
                    {selected && dayjs(selected.end_time).format('hh:mm A')}
                  </p>
                </div>
              </div>
              {/* {selected.status === 'started' && (
                <Button
                  variant='outlined'
                  label='Join Call'
                  className='mt-[51px]'
                />
              )} */}
              {/* {selected.status === 'completed' && (
                <p className='font-2xl font-semibold mt-5'>
                  Interview completed
                </p>
              )} */}
            </div>
          ) : (
            <>
              {finalInterviews &&
                // @ts-ignore
                Object.keys(finalInterviews)?.map(key => (
                  // @ts-ignore
                  <Accordion
                    key={key}
                    interviews={finalInterviews[key]}
                    setSelected={setSelected}
                  />
                ))}

              {/* <Job className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
                <p className='2xl:text-22 text-lg text-primary font-medium'>
                  Gamer Skull
                </p>
                <p className='2xl:text-base text-sm font-medium my-1'>
                  UX UI Designer{' '}
                </p>
                <p className='2xl:text-base text-sm font-medium'>
                  Final Round{' '}
                  <span className='text-warning'>
                    <i className='text-inherit'>Pending</i>
                  </span>
                </p>
                <div className='flex items-center gap-5 mt-2'>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.calender} alt='' />
                    <p className='text-13 font-medium'>09/12/22</p>
                  </div>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.clock} alt='' />
                    <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                  </div>
                </div>
              </Job>
              <Job className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
                <p className='2xl:text-22 text-lg text-primary font-medium'>
                  Gamer Skull
                </p>
                <p className='2xl:text-base text-sm font-medium my-1'>
                  UX UI Designer{' '}
                </p>
                <p className='2xl:text-base text-sm font-medium'>
                  Final Round{' '}
                  <span className=''>
                    <i className='text-inherit'>Completed</i>
                  </span>
                </p>
                <div className='flex items-center gap-5 mt-2'>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.calender} alt='' />
                    <p className='text-13 font-medium'>09/12/22</p>
                  </div>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.clock} alt='' />
                    <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                  </div>
                </div>
              </Job> */}
            </>
          )}
        </JobsSection>
      )}
    </Container>
  );
};

export default Interviews;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div``;
