import React, { useState, FC, useEffect, useRef } from 'react';

import styled from 'styled-components';
import Button from 'components/Button/Button';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { Tooltip } from 'react-tooltip';

import ASSETS from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';
import { IJob, IVideo } from 'types/interfaces';
import { followCompany, getJobByid, saveJob, viewJob } from 'http/jobService';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ROLES } from 'types/enums';
import QuillViewer from 'components/QuillViewer/QuillViewer';
import { convertNumberToK } from 'utils';
import { t } from 'i18next';
import { toast } from 'react-toastify';

dayjs.extend(relativeTime);
interface IProps {
  following?: boolean;
  index: number;
  post: IVideo;
}

const Post: FC<IProps> = ({ following = false, index, post }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [menuOpen, setMenuOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [job, setJob] = useState<IJob | null>(null);

  const [isFollowed, setIsFollowed] = useState(false);
  const [saved, setSaved] = useState(false);

  const { loading } = useSelector((state: RootState) => state.job);
  const { user } = useSelector((state: RootState) => state.auth);

  const ref = useRef<HTMLDivElement>(null);
  const [editorWidth, setEditorWidth] = useState(0);

  const [fLoading, setFLoading] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleApply = () => {
    if (!user?.role) {
      return dispatch.utils.setRoleModal(true);
    } else if (!user.introVideos?.length) {
      return navigate('/create-video');
    } else if (!user.job_title) {
      return navigate('/make-profile/step-1');
    } else if (!user.userDetails?.cv_url) {
      return navigate('/make-profile/step-3');
    }
    dispatch.job.handleGetJobById({
      id: post.job_id,
      onSuccess: () => {
        navigate('/apply/upload-documents');
      },
    });
  };

  const handleFollow = async () => {
    try {
      if (!isFollowed) {
        setFLoading(true);
        await followCompany(post.company_id);
        setIsFollowed(true);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setFLoading(false);
    }
  };

  const handleSaveJob = async () => {
    try {
      closeMenu();
      await saveJob(post.job_id);
      if (saved) {
        toast.success('Job unsaved successfully!');
      } else {
        toast.success('Job saved successfully!');
      }
      setSaved(!saved);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleReport = () => {
    closeMenu();
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report Job',
      message: 'Are you sure you want to report this job?',
      buttonTitle: 'Report',
      onClick: () => {
        if (job) {
          dispatch.reports.setType('job');
          dispatch.reports.setId(job.id);
        }
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const handleGetJobDetails = async () => {
    try {
      const { data } = await getJobByid(post.job_id);
      setJob(data.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleLoadData = () => {
    try {
      const videoElement = videoRef.current;

      if (videoElement && index === 0) {
        // Play the video immediately if it is the first video
        setVideoSrc(post.url || '');
        videoElement.play().catch((err: any) => {
          console.log('Immediate video playback failed: ', err);
        });
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleViewJob = async () => {
    try {
      await viewJob(post.job_id);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetJobDetails();
    setIsFollowed(post.is_followed > 0);
    setSaved(post.is_save_video > 0);

    if (ref.current) {
      setEditorWidth(Math.floor(ref.current.clientWidth * 0.42) - 40);
    }

    // eslint-disable-next-line
  }, [post]);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.7) {
          // Set the video source and play
          if (!videoSrc) {
            setVideoSrc(post.url || '');
            handleViewJob();
          }
          (entry.target as HTMLVideoElement).play().catch(err => {
            console.log('Video playback failed: ', err);
            // (entry.target as HTMLVideoElement).muted = true;
            // (entry.target as HTMLVideoElement).play().catch(err => null);
          });
        } else {
          // Pause the video if less than 70% in view
          (entry.target as HTMLVideoElement).pause();
        }
      });
    };

    const observerOptions = {
      root: null, // Use the viewport as the root
      threshold: 0.7, // Trigger when 70% of the video is in view
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
    // eslint-disable-next-line
  }, [videoSrc, post]);

  return (
    <Container
      className='scroll-item border border-grey-600 flex items-stretch rounded-lg overflow-hidden '
      ref={ref}
    >
      <div
        className='img-container h-full !flex-shrink-0  flex-[0_0_58%] bg-center bg-cover bg-no-repeat'
        style={{ background: `url(${ASSETS.post})` }}
      >
        <video
          src={videoSrc || ''}
          ref={videoRef}
          controls
          className={classNames('h-[100%] w-full bg-black', 'object-contain')}
          loop
          onLoadedData={handleLoadData}
          disablePictureInPicture
          controlsList='nodownload noplaybackrate'
        ></video>
      </div>
      <div className='post-info flex-[0_0_42%] px-[21px] py-[23px] flex flex-col bg-white'>
        <div className='flex items-start gap-[9px] relative'>
          <div className='menu absolute right-0 top-3'>
            <div className='menu-opener'>
              <img
                src={ASSETS.menu}
                className='cursor-pointer'
                onClick={toggleMenu}
                alt=''
              />
              {menuOpen && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden'>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleSaveJob}
                    >
                      <img src={ASSETS.bookmark} alt='' />
                      <p className='text-base font-medium'>
                        {saved ? t('Unsave Job') : t('Save Job')}
                      </p>
                    </div>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleReport}
                    >
                      <img src={ASSETS.flag} alt='' />
                      <p className='text-base font-medium'>{t('Report Job')}</p>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          <img
            src={job?.company_details.company_logo || ASSETS.preview}
            className='min-w-[100px] max-w-[100px] h-[100px] 2xl:min-w-[140px]  2xl:max-w-[100px] 2xl:h-[140px] object-cover rounded-full bg-grey-200'
            alt=''
          />
          <div>
            <Link to={`/other-employer/${post.company_id}`}>
              <p className='text-22 2xl:text-2xl font-bold mt-3 pl-[2.5px] max-w-[20ch]'>
                {post.company_name}
              </p>
            </Link>
            <div className='flex items-center mt-[6px]  pl-[2.5px]'>
              <p className='text-sm 2xl:text-base text-placeholder font-medium'>
                {post.job_title}
              </p>

              <Tooltip
                // style={{
                //   maxWidth: '300px',
                //   fontSize: '14px',
                //   color: '#000000',
                //   background: '#ffffff',
                //   boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                // }}
                className='max-w-[300px] !bg-white shadow-[rgba(149,_157,_165,_0.2)_0px_8px_24px] !text-black break-words !opacity-100 !z-50'
                anchorSelect={`#tooltip-${post.id}`}
                content={post.title_description}
              ></Tooltip>
              <img src={ASSETS.info} id={`tooltip-${post.id}`} alt='' />
            </div>
            <div className='flex items-center gap-[4px] my-[6px]'>
              <div className='w-6 flex justify-center'>
                <img src={ASSETS.location} className='w-5' alt='' />
              </div>
              <p className='text-xs 2xl:text-sm text-placeholder font-medium'>
                {job?.company_details?.location}{' '}
                <span className='text-placeholderColor'>
                  <span className='text-placeholderColor italic'>
                    ({job?.job_type})
                  </span>
                </span>
              </p>
            </div>
            {/* <div className='flex items-center gap-[4px]'>
              <div className='w-6 flex justify-center'>
                <img src={ASSETS.bag} className='w-5' alt='' />
              </div>
              <p className='text-xs 2xl:text-sm text-placeholder font-medium '>
                Entry Level{' '}
              </p>
              <div className='w-1 h-1 bg-placeholderColor rounded-full'></div>
              <span className='text-xs 2xl:text-sm text-placeholderColor'>
                Full Time
              </span>
            </div> */}
            <div className='flex gap-1 mt-4'>
              <Button
                label={
                  post.is_applied !== 0 && user?.role === 'A'
                    ? t('Applied')
                    : t('Apply')
                }
                className='2xl:!h-10 xl:!h-10'
                onClick={() => {
                  if (post.is_applied !== 0 && user?.role === 'A') {
                    return null;
                  }
                  handleApply();
                }}
                loading={loading}
              />
              <Button
                label={following || isFollowed ? t('Following') : t('Follow')}
                variant='outlined'
                className={classNames(
                  '2xl:!h-10 xl:!h-10',
                  (following || isFollowed) && '!border-black'
                )}
                onClick={handleFollow}
                loading={fLoading}
              />
            </div>
          </div>
        </div>
        <div className='scroll-view flex-grow overflow-auto h-0 mt-4 overflow-x-hidden'>
          <div className='flex items-center justify-between'>
            <p className='text-lg font-medium'>{post.company_name}</p>
            {job?.role === ROLES['Staffing Agent for Employer'] && (
              <div className='text-base text-primary border-[1.5px] border-primary px-4 py-2 rounded-3xl leading-3 font-semibold'>
                {t('Staffing Agent')}
              </div>
            )}
            {job?.role === ROLES['Recruiting Agent for Employer'] && (
              <div className='text-base text-primary border-[1.5px] border-primary px-4 py-2 rounded-3xl leading-3 font-semibold'>
                {t('Recruiter')}
              </div>
            )}
            {job?.role === ROLES.Employer && (
              <div className='text-base text-primary border-[1.5px] border-primary px-4 py-2 rounded-3xl leading-3 font-semibold'>
                {t('Employer')}
              </div>
            )}
          </div>
          <QuillViewer description={post.job_description} width={editorWidth} />
          {/* <ReactQuill
            value={JSON.parse(post.job_description)}
            readOnly={true}
            theme={'bubble'}
            // formats={['delta']}
            className='!p-0'
          /> */}
          <p className='text-lg font-medium mb-3'>{job?.title}</p>
          {job?.description && (
            <>
              <p className='text-lg'>Job Responsibilites:</p>
              <QuillViewer description={job.description} width={editorWidth} />
            </>
          )}
          {/* <p className='text-base leading-6 text-placeholder mt-2'>
            Wardiere Software is a cutting-edge IT company at the forefront of
            innovation and technology. Specializing in software development,
            security, and IT consulting, we pride ourselves on delivering
            dynamic and scalable solutions that empower businesses to thrive in
            the digital era. We are building the next gen travel platform and is
            looking for a super talented UI / UX designer to become the
            mastermind behind our visual identity, crafting experiences that
            captivate users across our mobile web and native app platforms.
          </p>
          <p className='text-lg mt-2 font-medium'>UX UI Designer</p>
          <p className='text-base font-medium mt-[6px] mb-[5px]'>
            Job Responsibilities:
          </p>
          <Li className='text-placeholder'>
            Assist in research artifacts like personas, journey maps, service
            design blueprints, etc
          </Li>
          <Li className='text-placeholder'>
            Assist in research artifacts like personas, journey maps, service
            design blueprints, etc Assist designing for different platforms;
            mobile; tablet; and desktop
          </Li>
          <Li className='text-placeholder'>
            Assist designing and creating prototypes with tools like Figma,
            Axure, InVision, Principle, etc.
          </Li>
          <p className='text-base font-medium mt-[6px] mb-[5px]'>
            Qualifications:
          </p>
          <Li className='text-placeholder '>
            Graduation in BS CS, or any IT Field
          </Li>
          <Li className='text-placeholder'>
            3+ years of experience as a UI / UX designer, with a portfolio
            showcasing exceptional web and native app design work
          </Li> */}
          {post.salary_from > 0 && post.salary_to > 0 && (
            <p className='text-base leading-6 font-medium mt-3'>
              Salary Range: ${convertNumberToK(post.salary_from)}/yr - $
              {convertNumberToK(post.salary_to)}/yr
            </p>
          )}
          <p className='text-13 font-medium mt-2'>
            {dayjs(post.created_at).fromNow()}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Post;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 22px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

// eslint-disable-next-line
const Li = styled.p`
  padding-left: 15px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  z-index: 0px;
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 10px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;
