import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import Unpost from 'Dialogs/Unpost/Unpost';
import UnpostJobReason from 'Dialogs/UnpostJobReason/UnpostJobReason';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { useNavigate } from 'react-router-dom';

const OpenJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [tab, setTab] = useState<'open' | 'closed' | 'draft'>('open');
  const [unpost, setUnpost] = useState(false);
  const [unpostJobReason, setUnpostJobReason] = useState(false);
  const [premium, setPremium] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');

  const { openJobs, closedJobs } = useSelector((state: RootState) => state.job);

  const handleOpenPremimum = () => setPremium(true);

  const onUnpost = () => setUnpostJobReason(true);

  const handleCreateJob = () => {
    navigate('/create-company');
  };

  const handleEditPost = (id: string) => {
    setSelectedJob(id);
    setUnpost(true);
  };

  const handleTab = (t: typeof tab) => {
    setTab(t);
  };

  const handleGotoJob = (id: string) => {
    navigate(`/job/${id}`);
  };

  useEffect(() => {
    dispatch.job.handleGetMyJobs();
  }, []);

  return (
    <div className='bg-white border border-grey-600 rounded-[12px] 2xl:p-10 p-5 mt-5'>
      <p className='2xl:text-2xl text-xl font-medium text-center'>
        {t('Open Jobs')}
      </p>
      <div className='tabs flex items-center justify-center 2xl:mt-[50px] mt-4 gap-3'>
        <Button
          label={t('Open')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'open' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('open')}
        />
        <Button
          label={t('Closed')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'closed' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('closed')}
        />
        <Button
          onClick={handleOpenPremimum}
          label={t('Draft')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'draft' &&
              '!border !border-grey-400 !bg-grey-100 !bg-primaryDisabled !text-grey-600 '
          )}
        />
      </div>
      {tab === 'open' && (
        <div className='flex flex-col 2xl:mt-[37px] mt-5 gap-2'>
          {openJobs.map(job => (
            <div
              key={job.job_id}
              className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white cursor-pointer'
              onClick={() => handleGotoJob(job.job_id)}
            >
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={job.company_logo || ASSETS.preview} alt='' />
              </div>
              <div>
                <div className='flex items-center gap-2'>
                  <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                    {job.company_name}
                  </p>
                  <img
                    src={ASSETS.editBlack}
                    onClick={e => {
                      e.stopPropagation();
                      handleEditPost(job.job_id);
                    }}
                    className='cursor-pointer'
                    alt=''
                  />
                </div>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  {job.title}
                </p>
                <p className='2xl:text-base text-sm'>{job.company_location}</p>
              </div>
            </div>
          ))}
          {openJobs.length === 0 && (
            <div className='flex flex-col w-full items-center'>
              <img src={ASSETS.no_job} alt='' />
              <p className='text-2xl text-primary font-medium my-5'>
                {t('No Open Positions yet')}
              </p>
              <Button
                label={t('Create a Job Post')}
                className='!h-[44px] !px-4'
                variant='outlined'
                onClick={handleCreateJob}
              />
            </div>
          )}
        </div>
      )}
      {tab === 'closed' && (
        <div className='flex flex-col 2xl:mt-[37px] mt-5 gap-2'>
          {closedJobs.map(job => (
            <div
              key={job.job_id}
              className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white '
            >
              <div className='img  2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={job.company_logo || ASSETS.preview} alt='' />
              </div>
              <div className='flex-grow  pr-[29px]'>
                <div className='flex flex-grow items-center  justify-between'>
                  <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                    {job.company_name}
                  </p>
                  <p className='text-xs font-medium bg-[#D9D9D9] px-[10px] py-[6px] rounded-2xl'>
                    Unposted
                  </p>
                </div>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  {job.title}
                </p>
                <p className='2xl:text-base text-sm'>{job.company_location}</p>
              </div>
            </div>
          ))}
          {closedJobs.length === 0 && (
            <div className='flex flex-col w-full items-center'>
              <img src={ASSETS.no_job} alt='' />
              <p className='text-2xl text-primary font-medium mt-5'>
                {t('No Closed Positions yet')}
              </p>
            </div>
          )}
        </div>
      )}
      {unpost && selectedJob && (
        <Unpost open={unpost} setOpen={setUnpost} onUnpost={onUnpost} />
      )}
      {unpostJobReason && (
        <UnpostJobReason
          open={unpostJobReason}
          setOpen={setUnpostJobReason}
          onUnpost={onUnpost}
          id={selectedJob}
        />
      )}
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title={t('Want to view your saved drafts?')}
          message={t(
            'Premium access will allow you to access saved drafts and many other features!'
          )}
        />
      )}
    </div>
  );
};

export default OpenJobs;
