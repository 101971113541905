import React, { useState, useContext, useEffect } from 'react';

import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AgoraContext } from 'context/AgoraContext';
import AC from 'agora-chat';

import Button from 'components/Button/Button';
import TimeField from 'components/TimeField/TimeField';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import DatePicker from 'components/DatePicker/DatePicker';
import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';
import { ICreateFinalInterview } from 'types/api';
import {
  createFinalRoundInterview,
  updateFinalInterviewMessage,
} from 'http/jobService';
import axios from 'axios';
import { toast } from 'react-toastify';

dayjs.extend(isToday);

const schema = object({
  start_date: string().required('Start date is required').label('Start date'),
  start_time: string().required('Start time is required').label('Start time'),
  end_time: string().required('End time is required').label('End time'),
});

export const convertTimeToDate = (time: string, d: number) => {
  const date = new Date();
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }
  date.setDate(d);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const Schedular = () => {
  const navigate = useNavigate();
  const conn = useContext(AgoraContext);
  const { selected } = useSelector((state: RootState) => state.chat);

  const [premium, setPremium] = useState(false);
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      start_date: '',
      start_time: '',
      end_time: '',
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        setError('');
        setLoading(true);
        const startDate = convertTimeToDate(
          values.start_time,
          dayjs(values.start_date).date()
        );
        const endDate = convertTimeToDate(
          values.end_time,
          dayjs(values.start_date).date()
        );

        const diffInMilliseconds = endDate.getTime() - startDate.getTime();
        const diffInMinutes = diffInMilliseconds / (1000 * 60);

        if (diffInMinutes > 90) {
          return setError('Duration must not exceed 90 minutes!');
        } else {
          setPremium(false);
        }

        if (dayjs(startDate).isAfter(dayjs(endDate))) {
          return setError('Start time must be before end time!');
        }

        if (dayjs(startDate).isToday()) {
          if (dayjs().isAfter(startDate)) {
            return setError(
              'Oops! Looks like this time has already passed. Please select another time.'
            );
          }
        }

        const startHours = startDate.getHours();
        const startMinutes = startDate.getMinutes();
        const endHours = endDate.getHours();
        const endMintues = endDate.getMinutes();
        const startTimeStr = `${
          startHours >= 10 ? startHours : `0${startHours}`
        }:${startMinutes >= 10 ? startMinutes : `0${startMinutes}`}`;
        const endTimeStr = `${endHours >= 10 ? endHours : `0${endHours}`}:${
          endMintues >= 10 ? endMintues : `0${endMintues}`
        }`;

        if (selected) {
          const data: ICreateFinalInterview = {
            time: startTimeStr,
            end_time: endTimeStr,
            date: dayjs(values.start_date).format('YYYY-MM-DD'),
            application_id: selected?.application_id,
            group_id: selected?.group_id,
            job_id: selected?.job_id,
          };
          const { data: res } = await createFinalRoundInterview(data);
          const msg = JSON.stringify(res.data);
          const message = AC.message.create({
            type: 'txt',
            chatType: 'groupChat',
            msg,
            to: selected.group_id,
            // TODO: Send msg json in ext
          });
          const d = await conn.send(message);
          const msgId = d.serverMsgId;
          await updateFinalInterviewMessage(res.data.id, msgId);
          navigate(-1);
        }
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const message = err.response?.data.message;
          setError(message);
          toast.error(
            'Time slot already booked!. Please try another time slot.'
          );
        }
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);

  return (
    <div className='flex-grow'>
      <div className='mx-auto w-full max-w-[566px] bg-white border border-grey-400 2xl:px-10 px-6 2xl:pt-[47px] pt-7  2xl:pb-[66px] pb-10 rounded'>
        <p className='2xl:text-32 text-2xl text-primary text-center font-medium'>
          Final Round Scheduler
        </p>
        <div className='2xl:mt-[52px] mt-10 flex flex-col gap-[18px]'>
          <DatePicker
            label=''
            value={formik.values.start_date}
            onChange={val => formik.setFieldValue('start_date', val)}
            disablePast
            error={
              formik.touched.start_date && formik.errors.start_date
                ? formik.errors.start_date
                : ''
            }
          />
          <div className='grid grid-cols-12 gap-[14px]'>
            <div className='col-span-6'>
              <TimeField
                label='Start time*'
                placeholder='Start time'
                onChange={val => {
                  formik.setFieldValue('start_time', val);
                }}
                error={
                  formik.touched.start_time && formik.errors.start_time
                    ? formik.errors.start_time
                    : ''
                }
              />
            </div>
            <div className='col-span-6'>
              <TimeField
                label='End time*'
                placeholder='End time'
                onChange={val => formik.setFieldValue('end_time', val)}
                error={
                  formik.touched.end_time && formik.errors.end_time
                    ? formik.errors.end_time
                    : ''
                }
              />
            </div>
          </div>
          {error && <p className='text-sm text-error'>{error}</p>}
        </div>
        <Button
          label='Schedule Video Call'
          className='w-full 2xl:mt-[76px] !text-lg mt-14'
          type='submit'
          loading={loading}
          onClick={() => formik.handleSubmit()}
        />
      </div>
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title='Increase Scheduling Time?'
          message='Get a premium account to unlock time limit and other features!'
        />
      )}
    </div>
  );
};

export default Schedular;
