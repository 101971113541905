import React, { ChangeEvent, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Textarea from 'components/Textarea/Textarea';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { IReport } from 'types/api';
import ASSETS from 'assets';
import { reportUser } from 'http/userService';

const options = [
  'No reason',
  'Inappropriate Content',
  'Someone is in danger',
  'Profile is scam or fake ',
  'Minor or Underage',
  'Other',
];

const Report = () => {
  const navigate = useNavigate();

  const { id, type } = useSelector((state: RootState) => state.reports);

  const [selected, setSelected] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
    setError('');
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!description) {
        setError('Required field');
      }
      const data: IReport = {
        reason: selected,
        isReport: true,
      };

      if (selected !== 'No reason') {
        data.description = description;
      }

      if (type === 'user') {
        data.user_id = id;
      } else if (type === 'company') {
        data.company_id = id;
      } else if (type === 'job') {
        data.job_id = id;
      }

      await reportUser(data);

      navigate('/explore');
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full  max-w-[749px] rounded bg-white border-[0.5px] border-grey-600 2xl:px-6 2xl:pt-9 2xl:pb-6 p-5'>
          <p className='2xl:text-2xl text-xl font-bold'>Select an action</p>
          <div className='flex flex-col gap-2 mt-5'>
            {options.map(opt => (
              <div
                key={opt}
                className={classNames(
                  'flex justify-between items-center bg-grey-100 2xl:h-12 h-10 px-6 border-b-[0.5px] border-b-[#8D8A95] cursor-pointer',
                  selected === opt && 'border-b-primary border-b-1 bg-grey-400'
                )}
                onClick={() => setSelected(opt)}
              >
                <p className={classNames('2xl:text-lg text-base font-medium')}>
                  {opt}
                </p>
                <img src={ASSETS.arrowRight} alt='' />
              </div>
            ))}
          </div>
          {!selected ? (
            <p className='text-18 text-center 2xl:my-[142px] my-[50px] max-w-[40ch] mx-auto'>
              Help us keep the Final Round safe by telling us why you are
              reporting this job
            </p>
          ) : (
            <div className='2xl:mt-10 mt-6'>
              {selected === 'No reason' ? (
                <>
                  {' '}
                  <Button
                    label='Submit'
                    className='w-full 2xl:!text-22 !text-lg mt-[22px]'
                    onClick={handleSubmit}
                    loading={loading}
                  />
                </>
              ) : (
                <>
                  <p className='2xl:text-2xl text-lg font-bold'>
                    Write a report
                  </p>
                  <p className='2xl:text-lg text-base font-normal mt-2'>
                    The more details you give the better we can evaluate your
                    report
                  </p>
                  <div className='mt-[13px]'>
                    <Textarea
                      label='Feedback'
                      placeholder='Write your feedback...'
                      rows={6}
                      value={description}
                      onChange={handleChange}
                      error={error}
                    />
                  </div>
                  <Button
                    label='Submit'
                    className='w-full 2xl:!text-22 !text-lg mt-[22px]'
                    onClick={handleSubmit}
                    loading={loading}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className='h-11'></div>
      </div>
    </>
  );
};

export default Report;
