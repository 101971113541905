import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

const JobApplications = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [tab, setTab] = useState<'applied' | 'draft'>('applied');

  const { appliedJobs } = useSelector((state: RootState) => state.myjobs);

  const [premium, setPremium] = useState(false);

  const handleTab = (t: typeof tab) => {
    setTab(t);
  };

  const handleDraft = () => {
    setPremium(true);
  };

  const handleGotoJob = (id: string) => {
    navigate(`/job/${id}`);
  };

  useEffect(() => {
    dispatch.myjobs.handleGetAppliedJobs();
  }, []);

  return (
    <div className='bg-white border border-grey-600 flex flex-col items-center rounded-[12px] 2xl:p-10 p-5  mt-5'>
      <p className='2xl:text-2xl text-xl font-medium text-center'>
        {t('Job Applications')}
      </p>
      <div className='tabs flex items-center 2xl:mt-8 mt-4 gap-3'>
        <Button
          label={t('Applied')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'applied' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('applied')}
        />
        <Button
          onClick={handleDraft}
          label={t('Draft')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'draft' &&
              '!border !border-grey-400 !bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
        />
      </div>
      {appliedJobs.length === 0 ? (
        <div className='flex flex-col items-center 2xl:mt-11 mt-8'>
          <img src={ASSETS.noApplications} className='2xl:w-auto w-16' alt='' />
          <p className='2xl:text-2xl text-lg text-primary font-medium text-center mt-3'>
            {t('No Job Application yet')}
          </p>
          <p className='2xl:text-22 text-xl'>
            {t('You need premium to acess them')}
          </p>
        </div>
      ) : (
        <div className='flex flex-col w-full 2xl:mt-[37px] mt-5 gap-2'>
          {appliedJobs.map(job => (
            <div
              key={job.job_id}
              className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white cursor-pointer'
              onClick={() => handleGotoJob(job.job_id)}
            >
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={job.company_logo || ASSETS.preview} alt='' />
              </div>
              <div>
                <div className='flex items-center gap-2'>
                  <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                    {job.name}
                  </p>
                </div>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  {job.title}
                </p>
                <p className='2xl:text-base text-sm'>{job.location}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title={t('Want to view your saved drafts?')}
          message={t(
            'Premium access will allow you to access saved drafts and many other features!'
          )}
        />
      )}
    </div>
  );
};

export default JobApplications;
