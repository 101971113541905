import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IActionDialog {
  open: boolean;
  title: string;
  message: string;
  buttonTitle: string;
  onClick: () => void;
  loading?: boolean;
}
interface IProps {
  forgotPassword: boolean;
  forgotEmail: boolean;
  emailVerification: {
    open: boolean;
    type: 'email' | 'forgot-password' | 'forgot-email' | 'phone';
  };
  emailVerified: {
    open: boolean;
    type: 'email' | 'forgot-password' | 'forgot-email' | 'phone';
  };
  actionDialog: IActionDialog | null;
  path: 'apply' | 'profile' | 'create-job';
  roleModal: boolean;
  actionLoading: boolean;
  imagePreview: {
    imgURL: string;
    open: boolean;
  };
  finalRound: string;
}

export const utils = createModel<RootModel>()({
  name: 'utils',
  state: {
    forgotEmail: false,
    forgotPassword: false,
    imagePreview: {
      imgURL: '',
      open: false,
    },
    emailVerification: {
      open: false,
      type: 'email',
    },
    emailVerified: {
      open: false,
      type: 'email',
    },
    actionDialog: null,
    path: 'profile',
    roleModal: false,
    actionLoading: false,
    finalRound: '',
  } as IProps,
  reducers: {
    setForgotEmail(state, payload: boolean) {
      state.forgotEmail = payload;
      state.emailVerification = { open: false, type: 'email' };
      state.forgotPassword = false;
    },
    setForgotPassword(state, payload: boolean) {
      state.forgotPassword = payload;
      state.forgotEmail = false;
      state.emailVerification = { open: false, type: 'email' };
    },
    setemailVerification(state, payload: IProps['emailVerification']) {
      state.emailVerification = payload;
      state.forgotPassword = false;
      state.forgotEmail = false;
    },
    setEmailVerified(state, payload: IProps['emailVerified']) {
      state.emailVerified = payload;
      state.emailVerification = { open: false, type: 'email' };
      state.forgotPassword = false;
      state.forgotEmail = false;
    },
    openActionDialog(state, payload: IActionDialog) {
      state.actionDialog = payload;
    },
    closeActionDialog(state) {
      state.actionDialog = null;
    },
    setPath(state, payload: IProps['path']) {
      state.path = payload;
    },
    setRoleModal(state, payload: boolean) {
      state.roleModal = payload;
    },
    setActionLoading(state, payload: boolean) {
      state.actionLoading = payload;
    },
    setOpenImagePreview(state, payload: string) {
      state.imagePreview = {
        imgURL: payload,
        open: true,
      };
    },
    closeImagePreview(state) {
      state.imagePreview = { imgURL: '', open: false };
    },
    setFinalRound(state, payload: string) {
      state.finalRound = payload;
    },
  },
});
