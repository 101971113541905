import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SETTINGS_ICONS from 'assets/settings';
import classNames from 'classnames';
import Language from './Components/Language';
import Safety from './Components/Safety';
import About from './Components/About';
import FAQs from './Components/FAQs';
import HelpCenter from './Components/HelpCenter';
import HelpCenterHotline from './Components/HelpCenterHotline';
import EmailNotification from './Components/EmailNotification';
import InappropriateContent from './Components/InappropriateContent';
import BlockAndReport from './Components/BlockAndReport/BlockAndReport';
import DeactivateAccount from 'Dialogs/DeactivateAccount/DeactivateAccount';
import ASSETS from 'assets';
import { CONSTANTS } from 'app.config';

type TAB =
  | 'language'
  | 'about'
  | 'safety-and-Privacy'
  | 'faqs'
  | 'help-center'
  | 'help-center-hotline'
  | 'email-notification'
  | 'inappropriate-content'
  | 'block-and-report'
  | '';

const CONFIG = [
  {
    icon: SETTINGS_ICONS.about,
    activeIcon: SETTINGS_ICONS.aboutActive,
    label: 'About',
    tab: 'about',
  },
  {
    icon: SETTINGS_ICONS.email,
    activeIcon: SETTINGS_ICONS.emailActive,
    label: 'Email Notifications',
    tab: 'email-notification',
  },
  {
    icon: SETTINGS_ICONS.faq,
    activeIcon: SETTINGS_ICONS.faqActive,
    label: 'FAQs',
    tab: 'faqs',
  },
  {
    icon: SETTINGS_ICONS.helpCenter,
    activeIcon: SETTINGS_ICONS.helpCenterActive,
    label: 'Help Center',
    tab: 'help-center',
  },
  {
    icon: SETTINGS_ICONS.helpCenter,
    activeIcon: SETTINGS_ICONS.helpCenterActive,
    label: 'Help Center Hotline',
    tab: 'help-center-hotline',
  },
  {
    icon: SETTINGS_ICONS.warning,
    activeIcon: SETTINGS_ICONS.warningActive,
    label: 'Inappropriate Content',
    tab: 'inappropriate-content',
  },
  {
    icon: SETTINGS_ICONS.language,
    activeIcon: SETTINGS_ICONS.languageActive,
    label: 'Language',
    tab: 'language',
  },
  {
    icon: SETTINGS_ICONS.safety,
    activeIcon: SETTINGS_ICONS.safetyActive,
    label: 'Safety and Privacy',
    tab: 'safety-and-Privacy',
  },
  {
    icon: SETTINGS_ICONS.block,
    activeIcon: SETTINGS_ICONS.blockActive,
    label: 'Block & Report',
    tab: 'block-and-report',
  },
];

const Settings = () => {
  const [t] = useTranslation();

  const [tab, setTab] = useState<TAB>('');
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleTab = (newTab: TAB) => setTab(newTab);

  const handleLogout = () => {
    localStorage.clear();
    window.location.pathname = '/';
  };

  return (
    <div className='flex-grow items-stretch flex gap-[15px]'>
      <div className='2xl:min-w-[290px] min-w-[250px]'>
        <div className=' bg-white rounded'>
          {CONFIG.map(item => (
            <div
              key={item.tab}
              onClick={() => handleTab(item.tab as TAB)}
              className={classNames(
                'tab 2xl:py-4 py-3 2xl:px-3 px-2 flex items-center justify-between gap-1 border-b border-b-grey-200 last-of-type:border-b-0 cursor-pointer',
                tab === item.tab &&
                  'relative before:w-1 before:h-full before:left-0 before:top-0 before:bg-primary  before:absolute'
              )}
            >
              <div className='flex items-center gap-3'>
                <div className='flex items-end justify-center w-[29px]'>
                  <img
                    src={tab === item.tab ? item.activeIcon : item.icon}
                    className='scale-[0.85] 2xl:scale-[1]'
                    alt=''
                  />
                </div>
                <p
                  className={classNames(
                    '2xl:text-lg text-base font-medium',
                    tab === item.tab && 'text-primary font-semibold'
                  )}
                >
                  {t(item.label)}
                </p>
              </div>
              <img
                src={
                  tab === item.tab
                    ? ASSETS.arrowForwardActive
                    : ASSETS.arrowForward
                }
                className='w-4 2xl:w-auto'
                alt=''
              />
            </div>
          ))}
          <div
            // key={item.tab}
            // onClick={() => handleTab(item.tab as TAB)}
            className={classNames(
              'tab 2xl:py-4 py-3 2xl:px-3 px-2 flex items-center justify-between gap-3 border-b border-b-grey-200 last-of-type:border-b-0 cursor-pointer'
            )}
            onClick={handleLogout}
          >
            <div className='flex items-center gap-3 cursor-pointer'>
              <div className='flex items-end justify-center w-[29px]'>
                <img
                  src={ASSETS.logout}
                  className='scale-[0.85] 2xl:scale-[1]'
                  alt=''
                />
              </div>
              <p className={classNames('2xl:text-lg text-base font-medium')}>
                {t('Logout')}
              </p>
            </div>
          </div>
        </div>
        <div
          className='tab py-4 pl-[30px] flex items-center gap-3 border-b border-b-grey-200
            last-of-type:border-b-0'
        >
          <p
            className='2xl:text-lg text-base font-medium cursor-pointer hover:text-primary'
            role='button'
            onClick={handleOpenModal}
          >
            {t('Deactivate Account')}
          </p>
        </div>
      </div>

      {tab.length > 0 && (
        <div className='flex-grow flex flex-col bg-white rounded border-[0.5px] border-grey-600'>
          <div className='flex-grow flex flex-col h-0 overflow-auto'>
            {tab === 'language' && <Language />}
            {tab === 'about' && <About />}
            {tab === 'safety-and-Privacy' && <Safety />}
            {tab === 'faqs' && <FAQs />}
            {tab === 'help-center' && <HelpCenter />}
            {tab === 'help-center-hotline' && <HelpCenterHotline />}
            {tab === 'email-notification' && <EmailNotification />}
            {tab === 'inappropriate-content' && <InappropriateContent />}
            {tab === 'block-and-report' && <BlockAndReport />}
          </div>
        </div>
      )}

      {openModal && (
        <DeactivateAccount open={openModal} setOpen={setOpenModal} />
      )}
    </div>
  );
};

export default Settings;
