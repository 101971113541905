import { FC, useEffect } from 'react';
import useCountdown from 'hooks/useCountDown';

interface IProps {
  timeInSeconds: number;
  setTime?: React.Dispatch<React.SetStateAction<number>>;
  onEnd?: () => void;
}

const Timer: FC<IProps> = ({ timeInSeconds, setTime, onEnd }) => {
  const { startTimer, time } = useCountdown(timeInSeconds);

  const formatTime = (seconds: number): string => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const formattedDays = String(days).padStart(2, '0');
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');

    return `${formattedDays} : ${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
  };

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (time === 0) {
      if (setTime) setTime(0);
      if (onEnd) onEnd();
    }
    console.log(time);
  }, [time]);

  return (
    <span className='p-2 flex justify-center text-base'>
      {formatTime(time)}
    </span>
  );
};

export default Timer;
